import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { HeapFilesApiService } from '@services/api';
import { CarouselService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class ImagesViewerService {
  private _subscriptions: Subscription;
  imagesBindingList = {};
  constructor(private heapFilesApiService: HeapFilesApiService, private carouselService: CarouselService) {}

  replaceImagesToSave(md: string) {
    Object.keys(this.imagesBindingList).forEach((key) => {
      md = (md || '').split(key).join(this.imagesBindingList[key]);
    });
    return md;
  }

  public init(images: NodeListOf<HTMLImageElement>) {
    images.forEach((imageNode) => {
      const id = imageNode.src.split('?id=')[1];
      if (!id) return;
      this.heapFilesApiService.getById(Number.parseInt(id)).subscribe((blob) => {
        const url = URL.createObjectURL(blob);
        this.imagesBindingList[url] = imageNode.src;
        imageNode.src = url;
      });
    });
    this._subscriptions = new Subscription();

    const _images = Array.from(images) as HTMLImageElement[];

    _images.forEach((image, index) => {
      this._subscriptions.add(fromEvent(image, 'click').subscribe(() => this._openImage(_images, index)));
    });
  }

  public destroy() {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  private async _openImage(images: HTMLImageElement[], currentIndex: number) {
    const url = images[currentIndex].getAttribute('src');
    // const alt = images[currentIndex].getAttribute('alt') || 'Картинка';
    const response = await fetch(url);
    const blob = await response.blob();

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.status} ${response.statusText}`);
    }
    const file = new File([blob], '', { type: blob.type });
    this.carouselService.openImageFile(file, crypto.randomUUID() + '.jpg');
  }
}
